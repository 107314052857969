.promptContainer {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .promptContainer h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .button {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--accent-color);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #c0392b;
  }