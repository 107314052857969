.currentGame {
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.deadlineInfo {
  font-color: #666;
}

.matchesTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.matchesTable th,
.matchesTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.matchesTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.teamLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.teamLabel input[type="radio"] {
  margin-right: 10px;
}

.submitButton {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.success {
  color: green;
  font-weight: bold;
}

.error {
  color: red;
  font-weight: bold;
}

.selectionGroup {
  margin-top: 20px;
}

.selectionItem {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.editButton {
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}