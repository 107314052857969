.currentGame {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #495057;
}

.deadlineInfo {
  font-style: italic;
  color: #6c757d;
}

.message {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  color: #721c24;
  text-align: center;
  font-size: 1.1em;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.1em;
}

.tableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  min-width: 600px;
  border-collapse: separate;
  border-spacing: 0 4px;
  text-align: left;
  margin-top: 20px;
}

.tableHeader th {
  background-color: var(--primary-color);
  color: white;
  padding: 12px;
  font-weight: bold;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table td {
  padding: 12px;
  text-align: center;
}

.selectionRow {
  transition: background-color 0.3s ease;
}

.evenRow {
  background-color: #f9f9f9;
}

.oddRow {
  background-color: white;
}

.selectionRow:hover {
  background-color: #f0f0f0;
}

.userName {
  font-weight: bold;
}

.playerCell {
  padding: 12px;
}

.scored {
  background-color: #4CAF50;
  color: white;
}

.not-scored {
  background-color: #F44336;
  color: white;
}

.pending {
  background-color: #f0f0f0;
  color: #666;
}

@media (max-width: 768px) {
  .table {
    font-size: 14px;
  }

  .table th,
  .table td {
    padding: 8px;
  }

  .currentGame {
    font-size: 0.9em;
  }

  .message {
    font-size: 1em;
    padding: 15px;
  }

  .tableContainer {
    position: relative;
  }

  .tableContainer::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.7));
    pointer-events: none;
  }
}

@media (max-width: 480px) {
  .table {
    font-size: 12px;
  }

  .table th,
  .table td {
    padding: 6px;
  }

  .currentGame {
    font-size: 0.8em;
  }

  .message {
    font-size: 0.9em;
    padding: 10px;
  }
}