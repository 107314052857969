.currentGame {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.selectionGroup {
  margin-bottom: 30px;
}

.sectionTitle {
  font-size: 1.8em;
  color: #37003c;
  margin-bottom: 15px;
  border-bottom: 2px solid #37003c;
  padding-bottom: 10px;
}

.selectionSet {
  margin-bottom: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.gameTitle {
  font-size: 1.3em;
  color: #37003c;
  margin-bottom: 10px;
}

.submittedDate {
  font-size: 0.9em;
  color: #6c757d;
  margin-bottom: 15px;
}

.selectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selectionRow {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e9ecef;
  gap: 20px;
}

.selectionInfo {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
}

.label {
  font-weight: bold;
  width: 70px;
  min-width: 70px;
  display: inline-block;
  color: #37003c;
}

.value {
  font-size: 1em;
}

.selectionActions {
  flex: 0 0 120px;
}

.select {
  width: 100%;
  min-width: 150px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.9em;
  transition: border-color 0.3s ease;
}

.select:focus {
  border-color: #37003c;
  outline: none;
}

.button, .editButton {
  width: 100%;
  padding: 10px;
  background-color: #37003c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover, .editButton:hover {
  background-color: #560a5e;
}

.error, .success {
  text-align: center;
  font-size: 1em;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
}

.error {
  color: white;
  background-color: #dc3545;
}

.success {
  color: white;
  background-color: #28a745;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.noCurrentSelections {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.warningMessage {
  color: #721c24;
  font-weight: bold;
}

.deadlineMessage {
  font-size: 1.2em;
  color: #721c24;
  margin-bottom: 15px;
}

.inlineError {
  color: #dc3545;
  font-size: 0.9em;
  margin-top: 5px;
}

.errorSelect {
  border-color: #dc3545;
}

.errorSelect:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

@media (max-width: 768px) {
  .selectionRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .selectionInfo {
    width: 100%;
  }

  .selectionActions {
    width: 100%;
    margin-top: 10px;
  }

  .select, .button, .editButton {
    font-size: 1em;
    padding: 10px;
  }

  .gameTitle {
    font-size: 1.2em;
  }

  .submittedDate {
    font-size: 0.8em;
  }

  .selectionSet {
    padding: 15px;
  }

  .sectionTitle {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .currentGame {
    padding: 15px;
  }

  .selectionSet {
    padding: 10px;
  }

  .gameTitle {
    font-size: 1.1em;
  }

  .sectionTitle {
    font-size: 1.3em;
  }

  .select, .button, .editButton {
    font-size: 0.9em;
    padding: 8px;
  }

  .label {
    width: 60px;
    min-width: 60px;
  }

  .value {
    font-size: 0.9em;
  }

  .deadlineMessage {
    font-size: 1em;
  }
}