.pageContainer {
  padding: 20px;
  padding-bottom: 80px;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f0f2f5;
}

.matchList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.matchItem {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.teamButtons {
  display: flex;
  justify-content: space-between;
}

.teamButton {
  flex: 1;
  padding: 15px 10px;
  border: none;
  background-color: #e9ecef;  /* Light gray for unselected */
  color: #495057;  /* Darker text for contrast */
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.teamButton:hover {
  background-color: #dee2e6;  /* Slightly darker on hover */
}

.teamButton.selected {
  background-color: #4a90e2;  /* Keep blue for selected */
  color: white;
}

.vsLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #f8f9fa;
  color: #6c757d;
  font-weight: bold;
  font-size: 12px;
}

.selectionCounter {
  position: sticky;
  top: 0;
  background-color: #4a90e2;
  color: white;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-bottom: 20px;
  border-radius: 0 0 12px 12px;
}

.submitButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 15px;
  font-size: 16px;
  z-index: 1000;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #357abd;
}

.submitButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .teamButton {
    padding: 12px 8px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .pageContainer {
    padding: 10px;
    padding-bottom: 70px;
  }

  .teamButton {
    font-size: 12px;
    padding: 10px 5px;
  }

  .submitButton {
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}