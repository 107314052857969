:root {
  --primary-color: #2c3e50;
  --secondary-color: #ecf0f1;
  --accent-color: #e74c3c;
  --text-color: #34495e;
  --background-color: #f9f9f9;
}

html, body {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.app {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 15px;
  box-sizing: border-box;
  flex: 1;
}

.content {
  flex: 1;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .container {
    padding: 15px 10px;
  }
  
  .content {
    padding: 20px 10px;
  }
}

.full-width-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.content-wrapper {
  padding: 10px;
  box-sizing: border-box;
}

.scrollable-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.scrollable-content {
  min-width: 100%;
}

@media (max-width: 480px) {
  .content-wrapper {
    padding: 5px;
  }
}