.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .nav {
    background-color: #f0f0f0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  .gameIndicator {
    font-weight: bold;
    color: #3498db;
    margin-right: 1rem;
  }
  
  .link {
    text-decoration: none;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .link:hover {
    background-color: #e0e0e0;
  }
  
  .activeLink {
    composes: link;
    background-color: #3498db;
    color: white;
  }
  
  .content {
    flex: 1;
    padding: 1rem;
  }