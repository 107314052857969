.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 640px) {
  .card {
    padding: 15px;
    margin-bottom: 15px;
  }
}
  
  .cardContent {
    padding: 20px;
    overflow-x: hidden; /* Add this line */
  }