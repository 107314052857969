.mainNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: relative;
}
  
  .navLeft, .navRight {
    display: flex;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
  
  .menuButton {
    display: none;
  }
  
  .menuDropdown {
    display: flex;
  }
  
  .menuItem {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
}

.menuItem:hover, .menuItem.active {
    background-color: #34495e;
}

.menuItem[onClick] {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.menuItem[onClick]:hover {
  background-color: white;
  color: #2c3e50;
}

.logoutButton {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.logoutButton:hover {
  background-color: white;
  color: #2c3e50;
}
  
  @media (max-width: 768px) {
    .menuButton {
      display: block;
      background: none;
      border: none;
      color: white;
      font-size: 1rem;
      cursor: pointer;
    }
  
    .menuDropdown {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #2c3e50;
      flex-direction: column;
      z-index: 1000;
    }
  
    .menuDropdown.open {
      display: flex;
    }
  
    .menuItem {
      padding: 15px 20px;
      border-top: 1px solid #34495e;
    }
  }