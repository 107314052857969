.container {
  max-width: 100%;
  padding: 10px;
}

.title {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.gameInfo {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}

.tableWrapper {
  overflow-x: auto;
}

.selectionsTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.selectionsTable th,
.selectionsTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.selectionsTable th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 12px;
}

.rankColumn {
  width: 30px;
  text-align: center;
}

.userColumn {
  width: 25%;
}

.picksColumn {
  width: 75%;
}

.userName {
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userScore {
  font-size: 12px;
  color: #666;
}

.picksContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.pick {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
  white-space: nowrap;
}

.win { background-color: #e8f5e9; color: #2e7d32; }
.loss { background-color: #ffebee; color: #c62828; }
.push { background-color: #fff3e0; color: #e65100; }
.pending { background-color: #f5f5f5; color: #616161; }

.message, .error {
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
}

.message { background-color: #e8f5e9; color: #1b5e20; }
.error { background-color: #ffebee; color: #b71c1c; }

@media (max-width: 480px) {
  .container { padding: 5px; }
  .title { font-size: 18px; }
  .selectionsTable { font-size: 12px; }
  .selectionsTable th, .selectionsTable td { padding: 6px 4px; }
  .rankColumn { width: 20px; }
  .userColumn { width: 30%; }
  .picksColumn { width: 70%; }
  .pick { font-size: 10px; padding: 1px 3px; }
}

.defaultIndicator {
  color: #ff9800;
  font-weight: bold;
  margin-left: 4px;
  font-size: 16px;
  vertical-align: super;
}

.legend {
  margin-top: 15px;
  font-size: 12px;
  color: #666;
}