.pageContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .pageTitle {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .currentGame {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .sectionTitle {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .deadlineInfo {
    color: #666;
    font-size: 0.9em;
  }
  
  .button {
    background-color: #2196F3;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #1976D2;
  }
  
  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .message {
    background-color: #fff3e0;
    border: 1px solid #ffe0b2;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .success {
    background-color: #e8f5e9;
    border: 1px solid #c8e6c9;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
    color: #2e7d32;
  }
  
  .error {
    background-color: #ffebee;
    border: 1px solid #ffcdd2;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
    color: #c62828;
  }
  
  @media (max-width: 768px) {
    .pageContainer {
      padding: 10px;
    }
  }