.gameNav {
    background-color: #f0f0f0;
    padding: 10px;
    position: relative;
  }
  
  .navList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .gameNavItem {
    text-decoration: none;
    color: #333;
    padding: 10px 15px;
    display: block;
  }
  
  .active {
    font-weight: bold;
    background-color: #e0e0e0;
  }
  
  .mobileMenuButton {
    display: none;
  }
  
  @media (max-width: 768px) {
    .mobileMenuButton {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #e0e0e0;
      border: none;
      text-align: left;
      font-size: 16px;
      cursor: pointer;
    }
  
    .navList {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #f0f0f0;
      z-index: 1000;
    }
  
    .navList.open {
      display: flex;
    }
  
    .gameNavItem {
      border-top: 1px solid #ccc;
    }
  }