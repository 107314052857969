.homepage {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  .homepage h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .gameSelection {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .gameOption {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 1.5rem;
    width: 250px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .gameOption:hover {
    transform: translateY(-5px);
  }
  
  .gameOption h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #2c3e50;
  }
  
  .gameLink {
    display: block;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .gameLink:hover {
    background-color: #2980b9;
  }
  
  @media (max-width: 600px) {
    .homepage {
      padding: 1rem;
    }
  
    .gameOption {
      width: 100%;
    }
  }