.currentGame {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.sectionTitle {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #37003c;
}

.deadlineInfo {
  font-style: italic;
  color: #6c757d;
  margin-bottom: 20px;
}

.message, .error, .success {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 1.1em;
}

.message {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  color: #495057;
}

.error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.success {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.form {
  margin-top: 30px;
}

.selectionsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.selectionRow {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.selectionRow:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.selectionNumber, .selectionTeam, .selectionPlayer {
  padding: 15px;
}

.selectionNumber {
  width: 20%;
  font-weight: bold;
  color: #37003c;
}

.selectionTeam, .selectionPlayer {
  width: 40%;
}

.select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.select:focus {
  border-color: #37003c;
  outline: none;
}

.errorSelect {
  border-color: #dc3545;
}

.errorMessages {
  margin-top: 15px;
}

.errorMessage {
  color: #dc3545;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.submitButton {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 30px;
  background-color: #37003c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #560a5e;
}

@media (max-width: 768px) {
  .selectionsTable {
    font-size: 14px;
  }
  
  .selectionNumber, .selectionTeam, .selectionPlayer {
    padding: 10px;
  }

  .sectionTitle {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .selectionsTable {
    font-size: 12px;
  }

  .selectionNumber, .selectionTeam, .selectionPlayer {
    padding: 8px;
  }

  .select {
    font-size: 0.9em;
  }

  .sectionTitle {
    font-size: 1.3em;
  }

  .currentGame {
    padding: 15px;
  }
}