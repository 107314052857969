.currentGame {
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sectionTitle {
  color: #2c3e50;
  font-size: 1.8em;
  margin-bottom: 10px;
  font-weight: 600;
}

.deadlineInfo {
  color: #34495e;
  font-size: 1em;
  font-weight: 500;
}

.selectionGroup {
  margin-bottom: 40px;
}

.selectionGroup h3 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.selectionItem {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.selectionItem:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.selectionItem p {
  margin: 10px 0;
  font-size: 1.1em;
  color: #34495e;
}

.selectionItem p strong {
  font-weight: 600;
  color: #2c3e50;
}

.editButton {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.editButton:hover {
  background-color: #2980b9;
}

.noCurrentSelections {
  background-color: #fff3e0;
  border: 1px solid #ffe0b2;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.warningMessage {
  color: #e67e22;
  margin: 0;
  font-size: 1.1em;
}

.noSelections {
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  color: #2e7d32;
  font-size: 1.1em;
}

.success {
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  color: #2e7d32;
  font-size: 1.1em;
}

.error {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  color: #c62828;
  font-size: 1.1em;
}

.matchesTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.matchesTable th,
.matchesTable td {
  border: 1px solid #e0e0e0;
  padding: 15px;
  text-align: left;
}

.matchesTable th {
  background-color: #f2f2f2;
  font-weight: 600;
  color: #2c3e50;
}

.matchesTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.matchesTable tr:hover {
  background-color: #f5f5f5;
}

.matchesTable label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.matchesTable input[type="radio"] {
  margin-right: 10px;
}

form button {
  background-color: #2ecc71;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

form button[type="button"] {
  background-color: #e74c3c;
}

form button:hover {
  opacity: 0.9;
}