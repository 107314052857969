.registerContainer {
  max-width: 400px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.registerContainer h2 {
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 24px;
}

.inputGroup {
  margin-bottom: 20px;
  text-align: left;
}

.inputGroup label {
  display: block;
  margin-bottom: 8px;
  color: #34495e;
  font-weight: 500;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.inputGroup input:focus {
  outline: none;
  border-color: #3498db;
}

.registerButton {
  width: 100%;
  padding: 12px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.registerButton:hover {
  background-color: #27ae60;
}

.error {
  color: #e74c3c;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.loginPrompt {
  margin-top: 20px;
  font-size: 14px;
  color: #7f8c8d;
}

.loginPrompt a {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
}

.loginPrompt a:hover {
  text-decoration: underline;
}