.selectionGroup {
  margin-bottom: 30px;
}

.selectionGroup h3 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.selectionItem {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectionDetails {
  flex-grow: 1;
}

.teamInfo {
  font-weight: bold;
  color: #2c3e50;
}

.matchInfo {
  color: #7f8c8d;
  font-size: 0.9em;
  margin-top: 5px;
}

.statusInfo {
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  min-width: 80px;
}

.statusPending {
  background-color: #f39c12;
  color: white;
}

.statusWin {
  background-color: #2ecc71;
  color: white;
}

.statusLoss {
  background-color: #e74c3c;
  color: white;
}

.editButton {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.editButton:hover {
  background-color: #2980b9;
}

.noCurrentSelections {
  background-color: #fff3e0;
  border: 1px solid #ffe0b2;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.warningMessage {
  color: #e67e22;
  margin: 0;
  font-size: 1.1em;
}

.archivedSelections {
  margin-top: 40px;
}

.gameweekContainer {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.gameweekTitle {
  font-size: 1.3em;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 600;
}

.score {
  font-size: 1.2em;
  font-weight: 600;
  color: #2c3e50;
  margin-top: 15px;
}

.editForm {
  margin-top: 20px;
}

.matchList {
  margin-bottom: 20px;
}

.matchItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
}

.teamButton {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  font-size: 0.9em;
}

.teamButton:hover {
  background-color: #e0e0e0;
}

.teamButton.selected {
  background-color: #4CAF50;
  color: white;
}

.vsLabel {
  margin: 0 10px;
  font-weight: bold;
}

.kickoffTime {
  font-size: 0.8em;
  color: #666;
  white-space: nowrap;
}

.formButtons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.cancelButton {
  background-color: #f44336;
}

.cancelButton:hover {
  background-color: #d32f2f;
}

.archivedSelections {
  margin-top: 40px;
}

.gameweekContainer {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.gameweekTitle {
  font-size: 1.3em;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 600;
}

.score {
  font-size: 1.2em;
  font-weight: 600;
  color: #2c3e50;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .matchItem {
    flex-direction: column;
    align-items: stretch;
  }

  .teamButton {
    margin-bottom: 5px;
  }

  .vsLabel {
    text-align: center;
    margin: 5px 0;
  }

  .kickoffTime {
    text-align: center;
    margin-top: 5px;
  }
}