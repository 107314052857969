.currentGame {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.sectionTitle {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.deadlineInfo {
  color: #666;
  font-size: 0.9em;
}

.selectionGroup {
  margin-bottom: 30px;
}

.selectionItem {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.userName {
  font-weight: bold;
  color: #444;
}

.kickoffTime {
  font-size: 0.8em;
  color: #777;
  margin-top: 5px;
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.statusWin {
  background-color: #e8f5e9;
}

.statusLoss {
  background-color: #ffebee;
}

.statusPending {
  background-color: #f5f5f5;
}

.message {
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  color: #2e7d32;
}

.error {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  color: #c62828;
}