.profileContainer {
  max-width: 400px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profileContainer h2, .profileContainer h3 {
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

.userInfo {
  margin-bottom: 30px;
}

.userInfo p {
  margin-bottom: 10px;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup label {
  display: block;
  margin-bottom: 8px;
  color: #34495e;
  font-weight: 500;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.inputGroup input:focus {
  outline: none;
  border-color: #3498db;
}

.resetButton {
  width: 100%;
  padding: 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.resetButton:hover {
  background-color: #2980b9;
}

.message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
  text-align: center;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}