.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.title {
  font-size: 2.5em;
  color: #37003c;
  text-align: center;
  margin-bottom: 30px;
}

.gameweekTitle {
  font-size: 1.8em;
  color: #37003c;
  margin-bottom: 10px;
}

.deadline {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.fixturesTitle {
  font-size: 1.4em;
  color: #37003c;
  margin-bottom: 10px;
}

.fixturesTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-bottom: 20px;
}

.fixturesTable th,
.fixturesTable td {
  padding: 12px;
  text-align: left;
}

.fixturesTable th {
  background-color: #37003c;
  color: white;
  font-weight: bold;
}

.fixturesTable tr {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.fixturesTable tr:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.fixturesTable td:first-child,
.fixturesTable td:nth-child(2) {
  font-weight: bold;
}

.links {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  flex-wrap: wrap;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #37003c;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: bold;
  margin: 10px 5px;
}

.button:hover {
  background-color: #560a5e;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .home {
    padding: 15px;
  }

  .title {
    font-size: 2em;
  }

  .gameweekTitle {
    font-size: 1.5em;
  }

  .deadline {
    font-size: 1em;
  }

  .fixturesTable {
    font-size: 14px;
  }

  .fixturesTable th,
  .fixturesTable td {
    padding: 8px;
  }

  .links {
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.8em;
  }

  .gameweekTitle {
    font-size: 1.3em;
  }

  .fixturesTable {
    font-size: 12px;
  }

  .fixturesTable th,
  .fixturesTable td {
    padding: 6px;
  }
}