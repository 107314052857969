.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sectionTitle {
    font-size: 22px;
    color: #444;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .leaderboardTable, .breakdownTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .leaderboardTable th,
  .leaderboardTable td,
  .breakdownTable th,
  .breakdownTable td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .leaderboardTable th,
  .breakdownTable th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .leaderboardTable tr:last-child td,
  .breakdownTable tr:last-child td {
    border-bottom: none;
  }
  
  .leaderboardTable tr:nth-child(even),
  .breakdownTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .rank {
    font-weight: bold;
    color: #333;
  }
  
  .score {
    font-weight: bold;
    color: #4CAF50;
  }
  
  .error {
    color: #d32f2f;
    background-color: #ffcdd2;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .title {
      font-size: 24px;
    }
  
    .sectionTitle {
      font-size: 20px;
    }
  
    .leaderboardTable,
    .breakdownTable {
      font-size: 14px;
    }
  
    .leaderboardTable th,
    .leaderboardTable td,
    .breakdownTable th,
    .breakdownTable td {
      padding: 8px;
    }
  }